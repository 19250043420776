<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    destory-on-close
    @close="closeDialog"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="时段名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="时段名称"
        ></el-input>
      </el-form-item>
      <el-form-item prop="isUse">
        <el-checkbox v-model="dataForm.isUse" :disabled="disabled"
          >是否使用</el-checkbox
        >
      </el-form-item>
      <el-form-item label="起始时间" prop="startTime">
        <el-time-picker
          :disabled="disabled"
          v-model="dataForm.startTime"
          value-format="HH:mm"
          format="HH:mm"
          placeholder="起始时间"
        ></el-time-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="endTime">
        <el-time-picker
          :disabled="disabled"
          v-model="dataForm.endTime"
          value-format="HH:mm"
          format="HH:mm"
          placeholder="结束时间"
        ></el-time-picker>
      </el-form-item>
      <el-form-item label="自由预售开始时间" prop="advanceStartTime">
        <el-time-picker
          :disabled="disabled"
          v-model="dataForm.advanceStartTime"
          value-format="HH:mm"
          format="HH:mm"
          placeholder="自由预售开始时间"
        ></el-time-picker>
      </el-form-item>
      <el-form-item label="统一配送自由预售截单时间" prop="cutOffTimeAny">
        <span
          >{{(dataForm.cutOffTimeAny && dataForm.startTime) ? (dataForm.cutOffTimeAny &gt;= dataForm.startTime ? '前一天' : '当天') : '' }}</span
        >
        <el-time-picker
          :disabled="disabled"
          v-model="dataForm.cutOffTimeAny"
          value-format="HH:mm"
          format="HH:mm"
          placeholder="统一配送自由预售截单时间"
        ></el-time-picker>
      </el-form-item>
      <el-form-item label="统一配送限定预售截单时间" prop="reservationTimeAny">
        <span>{{ (dataForm.cutOffTimeAny &gt; dataForm.startTime) &&
        (dataForm.reservationTimeAny &gt; dataForm.cutOffTimeAny) ? "前一天" : "当天" }}</span>
        <el-time-picker
          :disabled="disabled"
          v-model="dataForm.reservationTimeAny"
          value-format="HH:mm"
          format="HH:mm"
          placeholder="统一配送限定预售截单时间"
        ></el-time-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { keys, pick } from 'lodash';

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: '',
        name: '',
        isUse: false,
        startTime: '',
        endTime: '',
        cutOffTime: '',
        cutOffTimeAny: '',
        advanceStartTime: '',
        reservationTime: '',
        reservationTimeAny: '',
      },
      dataRule: {
        name: [
          { required: true, message: '时段名称不能为空', trigger: 'blur' },
        ],
        startTime: [
          { required: true, message: '起始时间不能为空', trigger: 'blur' },
        ],
        endTime: [
          { required: true, message: '结束时间不能为空', trigger: 'blur' },
        ],
        advanceStartTime: [
          {
            required: true,
            message: '自由预售开始时间不能为空',
            trigger: 'blur',
          },
        ],
        cutOffTimeAny: [
          {
            required: true,
            message: '统一配送自由预售截单时间不能为空',
            trigger: 'blur',
          },
        ],
        reservationTimeAny: [
          {
            required: true,
            message: '统一配送限定预售截单时间不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/cc/global-period/id/query/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.dataForm = pick(data.data, keys(this.dataForm));
            }
          });
        }
      });
    },
    closeDialog() {
      this.visible = false;
      this.dataForm = {
        id: '',
        name: '',
        isUse: false,
        startTime: '',
        endTime: '',
        cutOffTime: '',
        cutOffTimeAny: '',
        advanceStartTime: '',
        reservationTime: '',
        reservationTimeAny: '',
      };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/global-period/${
              !this.dataForm.id ? 'save' : 'id/update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            } else {
              this.$message.error(data.errors.endTime);
            }
          });
        }
      });
    },
  },
};
</script>
